import { Container, Content, ContentContainer, Headline, Image, ImageContainer, ImageHolder, Preheader, StyledGeneralHeader, Subheadline } from "./GeneralHeader.styles"
import React, { useContext } from 'react';

import { AnalyticsContext } from "../../../context"
import Button from '../Button'
import PropTypes from 'prop-types';
import { getPageSlug } from '../../../../helpers';
import MarketoForm from '../MarketoForm';
import { queries } from "../../../breakpoints"
import styled from 'styled-components';
import SectionWrapper from "../../sections/SectionWrapper"

const GeneralHeader = (props) => {
  const { handleTealiumEvent } = useContext(AnalyticsContext);

  const showThankYouScreen = () => {
    // Show the thank you message
    let thankYou = document.getElementById('marketo-thank-you')
    thankYou.style.display = 'block';

    // Hide the form
    let form = document.getElementById('marketo-form')
    form.style.display = "none"
  }

  const handleSubmit = () => {
    if(props.onBeforeSubmit) {
      props.onBeforeSubmit();
    }
    showThankYouScreen();
  }
  
  const cssFormFullWidth = () =>{
    let formsList = [ 2068 ];
    return formsList.includes(props.formID) ? 'fullwidth-form' : '';
  }

  const addEnhancedConversions = () =>{
    if( props.formEnhancedConversions )return (
        <>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-1070034498"></script>
          <script>
            {
              `window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments)}
              gtag('js', new Date());
              // config enhanced conversions
              gtag('config', 'AW-1070034498', {'allow_enhanced_conversions':true});`
            }
          </script>
        </>
    );
  }

  const renderCTAorForm = () => {
    if (props.formID && typeof props.formID === "number") {
      return(
        <div>
          <StyledSectionWrapper id="marketo-form" sectionPadding="0px">
            <StyledFormContainer className="row">
              <FormWrapper className="col-12" style={{ backgroundColor: props.backgroundColorForm }}>
                <h2>{props.formHeading}</h2>
                {addEnhancedConversions()}
                <FormStyles className={`${cssFormFullWidth()}`}>
                  <MarketoForm labelBellow={false} id={props.formID}
                               formEnhancedConversions={props.formEnhancedConversions}
                               businessEmailsUsage={props.businessEmailsUsage}
                               businessEmailUTMLink={props.businessEmailUTMLink}
                               onSubmit={() => handleSubmit()}/>
                </FormStyles>
                <Legal style={{ marginTop: `25px` }}>By clicking "Submit" or by using this site, you agreee to Grubhub's <a href="https://www.grubhub.com/legal/terms-of-use">terms of use</a> and <a href="https://www.grubhub.com/legal/privacy-policy">privacy policy</a>.</Legal>
              </FormWrapper>
            </StyledFormContainer>
          </StyledSectionWrapper>
          <ThankYouMessageContainer id="marketo-thank-you" className="col-12" style={{ display: 'none' }}>
              <img
                width={125}
                alt=""
                src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627673958/Grubhub%20Corporate%20Website/2021/Navigation/SuccessState-Checkmark.svg"/>
              <h2>Thanks for reaching out, we'll be in touch shortly!</h2>
              <p>In the meantime, check out our blog to learn more about supporting your hybrid workforce.</p>
              <Button to="/blog/" typee="primary">See blog</Button>
            </ThankYouMessageContainer>
        </div>
      );
    } else {
      if (props.ctaLink) return(
        <Button
          to={props.ctaLink}
          onClick={() => handleTealiumEvent({ eventcategory: props.eventCategoryOptional, eventaction: props.eventLabelOptional, eventlabel: props.eventActionOptional })}
        >
          {props.ctaText}
        </Button>
      );
    }
  }

  const renderImageContainer = () => {
    return (
        <ImageContainer>
            <Image style={{
              backgroundImage: `url(${props.imageSrc})`
            }}
            type={props.type}
            ></Image>
            <ImageHolder
                /**
                 * This img tag is here to fill space in the div so that the div above can be styled as a backgroundImage but
                 * still hold the proper amount of space without setting an explicit width and height.
                 * This placeholder is hidden on the page and z-indexed behind all the content
                 **/
                src={props.imageSrc}
                alt={props.alt}
                style={{display: "none"}}
            />
        </ImageContainer>
    );
  }

  return(
    <StyledGeneralHeader type={props.type} style={{ background: props.background, color: props.textColor, ...props.style }} hasChildren={props.children}>
      {props.children ?
        // Add in a children override so we can manually build headers but also have a general layout for less specific use
        <>{props.children}</>
      :
          <Container type={props.type} id="general-header" className={`${props.type !== "half-image" && "container"}`}>
          <ContentContainer>
            <Content>
              {props.preheader && <Preheader style={{ color: props.preheaderColor }}>{props.preheader}</Preheader>}
              {props.headline && <Headline style={{ color: props.textColor }}>{props.headline}</Headline>}
              {props.subheadline && <Subheadline style={{ color: props.textColor }}>{props.subheadline}</Subheadline>}
              {renderCTAorForm()}
            </Content>
          </ContentContainer>
            {props.type !== "full-image" && renderImageContainer()}
        </Container>
      }
      {props.type === "full-image" && !props.hasChildren && renderImageContainer()}
    </StyledGeneralHeader>
  )
};

GeneralHeader.propTypes = {
  preheader: PropTypes.string,
  headline: PropTypes.string,
  subheadline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  background: PropTypes.string,
  preheaderColor: PropTypes.string,
  textColor: PropTypes.string,
  type: PropTypes.oneOf(["half-image", "normal"]),
  alt: PropTypes.string,
  imageSrc: PropTypes.string,
  style: PropTypes.shape({}),
  eventCategoryOptional: PropTypes.string,
  eventLabelOptional: PropTypes.string,
  eventActionOptional: PropTypes.string,
  onBeforeSubmit: PropTypes.func,
  formEnhancedConversions: PropTypes.bool,
  businessEmailsUsage: PropTypes.bool,
  businessEmailUTMLink: PropTypes.string
};

GeneralHeader.defaultProps = {
  preheader: null,
  headline: null,
  subheadline: null,
  ctaLink: null,
  ctaText: "Get started",
  background: "radial-gradient(circle, #612889 0%, var(--color-primary-darker) 100%)",
  preheaderColor: "#aeaeae",
  textColor: "white",
  type: "normal",
  alt: "",
  imageSrc: "https://media-cdn.grubhub.com/image/upload/f_auto,fl_lossy,q_80/v1611769738/corporate/20190802_B2B_GH_GroupOrderAngle_HERO_1849_PS.jpg",
  style: null,
  eventCategoryOptional: getPageSlug() + ' interaction',
  eventLabelOptional: "get started_cta",
  eventActionOptional: "hero banner",
  onBeforeSubmit: () => {},
  formEnhancedConversions: false,
  businessEmailsUsage: false,
  businessEmailUTMLink: "https://www.grubhub.com"
};

const StyledSectionWrapper = styled(SectionWrapper)`
  margin-top: ${props => props.type !== "full-image" ? "45px" : "25px;"};    
  margin-bottom: 15px;
  @media(${queries.max_break_md}){
    padding:0;
    padding-bottom: 50px;
    margin-top: 25px;
    margin-bottom: 0px;
    .container{
      max-width: unset;
    }
  }
`;

const StyledFormContainer = styled.div`
    background-color: #f6f6f6;
    -webkit-box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
    -moz-box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
    font-family: var(--font-body);
    box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
    min-height: 50vh;
    border-radius:4px;
    @media(${queries.max_break_lg}){
        flex-wrap: wrap-reverse;
    }
`;
const FormWrapper = styled.div`
  display:flex;
  flex-flow:column;
  justify-content:center;
  align-items: flex-start;
  text-align:left;
  padding:5px 15px 35px;
    @media(${queries.min_break_lg}){
        padding: 20px 50px 50px;
    }
    h2{
        font-weight:bold;
        font-size: var(--fontSize-4);
        margin:20px 0;
        @media(${queries.max_break_md}){
          text-align: center;
          width: 100%;
        }
    }
`;
const Legal = styled.small`
    color:#A5A4AA;
    font-size: var(--fontSize-legal);
    font-weight: var(--fontWeight-light);
    width:215px;
`;
const FormStyles = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    font-family: var(--font-body);
    @media(${queries.max_break_lg}){
        width:100%;
    }

    .mktoFormCol {
        margin-bottom: 25px;
        @media(${queries.min_break_md}){
            margin-bottom: 35px;
            width: 50% !important;

            &:first-child {
                margin-right: 10px;
            }

            &:nth-child(2) {
                margin-left: 10px;
            }
        }
    }

    .mktoLabel {
        display: flex;
        font-size: var(--fontSize-0);
        font-weight: 300;
        margin-bottom: 3px;
        color: var(--color-secondary);
    }

    .mktoFormRow {
        display: flex;
        width: 100% !important;
        flex-flow: column;
        @media(${queries.min_break_md}){
            flex-flow: row;
        }
    }

    input {
        height: 40px;
        border: 1px solid #AEAEAE;
        border-radius: 2px;
        width: 100% !important;
        font-family: var(--fontFamily-body);
        padding: 0 10px;
    }

    .mktoButton {
        display: inline;
        padding: 12px 30px;
        border-radius: 100px;
        font-weight: bold;
        font-size: var(--fontSize-1);
        min-width: 175px;
        display: inline-block;
        text-align: center;
        background-color: var(--color-primary);
        color: white;
        font-family: var(--font-body);
        border: none;
        transition: all .1s;

        &:hover {
            color: white;
            text-decoration: none;
            background-color: var(--color-primary-dark);
        }
        &:active{
            background-color: var(--color-primary-darker);
        }
    }

    // Validation styles
    .mktoInvalid{
        border-color:#C3363E;
    }
    .mktoErrorMsg{
        font-family: var(--fontFamily-body);
        color:#C3363E;
        font-size: 12px;
    }

    .mktoValid{
        border-color: #13AA37;
    }
    @media(${queries.max_break_md}){
        #Phone, #PostalCode{
            max-width:200px;
        }
    }
    .mktoRadioList {
        max-width: 200px;
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .mktoRadioList input[type="radio"] {
        top: inherit !important;
        left: inherit !important;
        width: inherit !important;
        position: inherit !important;
        float: right;
        clear: right;
        width: 20px !important;
        height: 20px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
    .mktoRadioList label {
        display: block !important;
        float: left;
        clear: left;
        margin: 0 !important;
        padding: 0 !important;
        margin-right: 20px!important;
        padding-left: 4px!important;
        font-size: var(--fontSize-0);
        font-weight: 300;
        color: #333;
    }
`;

const ThankYouMessageContainer = styled.div`
  background-color: #FFF;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
  -moz-box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
  font-family: var(--font-body);
  box-shadow: 0px 2px 8px 0px rgba(207, 198, 198, 0.5);
  display:flex;
  flex-flow:column;
  justify-content:center;
  align-items: center;
  text-align:center;
  padding: 50px;
  margin-top: 45px;
  margin-bottom: 15px;
  @media(${queries.max_break_md}){
    margin-top: 25px;
    margin-bottom: 0px;
  }
  h2{
    font-weight:bold;
    font-size: var(--fontSize-5);
    margin:20px 0;
    @media(${queries.max_break_md}){
      font-size: var(--fontSize-3);
    }
  }
  p{
    font-size: var(--fontSize-2);
    @media(${queries.max_break_md}){
      font-size: var(--fontSize-1);
    }
  }
`;


export default GeneralHeader;
