import React from 'react';
import PropTypes from 'prop-types';
import SectionWrapper from '../SectionWrapper';
import { Headline, Subheadline, Row, ValuePropHeadline, ValuePropImage, ValuePropContent, StyledValueProp } from './ValuePropsSection.styles'

const ValueProps = (props) => (
  <SectionWrapper className="value-prop-section" backgroundColor={props.backgroundColor} maxWidth={props.maxWidth}>
    {props.headline ? <Headline>{props.headline}</Headline> : ""}
    {props.subheadline ? <Subheadline>{props.subheadline}</Subheadline> : ""}
    <Row className="row">
      {props.valueProps?.map((value, i) => {
        return(
          <ValueProp
            key={i}
            layout={props.layout}
            imageSrc={value.imageSrc}
            alt={value.alt === undefined ? '' : value.alt}
            headline={value.headline}
            subheadline={value.subheadline}
            perRow={props.propsPerRow}
          />
        )
      })}
    </Row>
  </SectionWrapper>
);

ValueProps.propTypes = {
  headline: PropTypes.string,
  subheadline: PropTypes.string,
  layout: PropTypes.oneOf(["vertical", "horizontal"]).isRequired,
  valueProps: PropTypes.arrayOf(PropTypes.shape({
    imageSrc: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    subheadline: PropTypes.string.isRequired,
    alt: PropTypes.string
  })).isRequired,
  backgroundColor: PropTypes.string,
  propsPerRow: PropTypes.number
};

ValueProps.defaultProps = {
  headline: null,
  subheadline: null,
  valueProps: null,
  backgroundColor: 'white',
  propsPerRow: 3
};

export default ValueProps;




/**
 * @param {string} layout = [vertical | horizontal] determines the layout of the value props
 * @param {string} imageSrc = image src url
 * @param {string} alt = image alt
 * @param {string} headline = headline text
 * @param {string} subheadline = subheadline text
 */
const ValueProp = ({layout, imageSrc, alt, headline, subheadline, perRow}) => {

  alt = headline === '' ? alt : headline;

  return(
    <StyledValueProp layout={layout} className={`col-sm-12 col-md-${12/perRow}`}>
      <div><ValuePropImage src={imageSrc} alt={`${alt}`}/></div>
      <ValuePropContent>
        <ValuePropHeadline>{headline}</ValuePropHeadline>
        <p className="value-prop__subheadline">{subheadline}</p>
      </ValuePropContent>
    </StyledValueProp>
  )
}

ValueProp.propTypes = {
  layout: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  subheadline: PropTypes.string.isRequired
};

ValueProp.defaultProps = {
  layout: "vertical",
  imageSrc: "https://via.placeholder.com/350x200",
  headline: 'Value prop headline',
  subheadline: "Value prop subheadline"
};
